<template>
	<div class="container">
		<div class="title">基础信息</div>
		<a-form :model="detail.basic" layout="inline">
			<a-form-item label="应用图标">
				<div class="value">
					<img class="icon" :src="detail.basic.icon" />
				</div>
			</a-form-item>
			<a-form-item label="应用名称">
				<div class="value">{{detail.basic.name}}</div>
			</a-form-item>
			<!-- <a-form-item label="申请类型">
				<div class="value">
					<div >{{detail.basic.applyType===1?'上架':"下架"}}</div>
				</div>
			</a-form-item> -->
			<a-form-item label="业务类型">
				<div class="value">{{businessTypeList[detail.basic.businessType]}}</div>
			</a-form-item>
			<a-form-item label="依赖应用">
				<div class="value" v-if="detail.basic.dependApps">{{detail.basic.dependApps.toString()}}</div>
			</a-form-item>
			<a-form-item label="应用描述">
				<div class="value">{{detail.basic.detailDescription}}</div>
			</a-form-item>
			<a-form-item label="应用一句话描述">
				<div class="value">{{detail.basic.simpleDescription}}</div>
			</a-form-item>
			<div>
				<a-form-item label="帮助文档">
					<div class="value" v-if="detail.basic.docUrl">
						<a target="blank" :href="detail.basic.docUrl">{{detail.basic.docUrl}}</a>
					</div>
					<div class="value" v-if="detail.basic.docFile">
						<a :href="detail.basic.docFile.url">{{detail.basic.docFile.name}}</a>
					</div>
				</a-form-item>
			</div>
			<div>
				<a-form-item label="功能截图">
					<viewer :images="detail.basic.screenshots">
						<img class="screenshots" v-for="(item,index) in detail.basic.screenshots" :key="index"
							:src="item" alt="应用图片" />
					</viewer>
				</a-form-item>
			</div>
		</a-form>
		<div class="title">价格信息</div>
		<a-table :columns="priceColumns" :data-source="detail.price" bordered :pagination="false" size="small">
			<span slot="userScale" slot-scope="text">
				<template v-if="text==1">1-49</template>
				<template v-else-if="text==2">50-99</template>
				<template v-else-if="text==3">100-499</template>
				<template v-else-if="text==4">500以上</template>
			</span>
		</a-table>
		<div class="title">应用配置</div>
		<a-tabs>
			<a-tab-pane key="1">
				<template #tab><span class="required">后端</span></template>
				<a-form v-if="detail.app.server" :model="detail.app.server" layout="vertical">
					<a-form-item label="jar包">
						<div class="value">
							<a
								@click="download(detail.app.server.packageInfo.key)">{{detail.app.server.packageInfo.name}}</a>
						</div>
					</a-form-item>
					<a-form-item label="基础镜像">
						<div class="value">
							<div>{{detail.app.server.image}}</div>
						</div>
					</a-form-item>
					<a-form-item label="逻辑资源">
						<div class="value">
							<a-checkbox-group :disabled="true" v-model="detail.app.server.resources.needed"
								:options="resourceOptions" />
						</div>
					</a-form-item>
					<a-form-item label="SQL脚本" v-if="detail.app.server.resources.needed.includes(1)">
						<div class="value">
							<a
								@click="download(detail.app.server.resources.database.key)">{{detail.app.server.resources.database.name}}</a>
						</div>
					</a-form-item>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="2">
				<template #tab><span class="required">网页端</span></template>
				<a-form v-if="detail.app.web" :model="detail.app.web" layout="vertical">
					<a-form-item label="前端dist包">
						<div class="value">
							<a @click="download(detail.app.web.packageInfo.key)">{{detail.app.web.packageInfo.name}}</a>
						</div>
					</a-form-item>
					<a-form-item label="菜单及按钮配置">
						<a-table :columns="menus" :data-source="detail.app.web.menus" bordered :pagination="false"
							size="small">
							<span slot="button" slot-scope="text,record">
								<a-button @click="showButton(record.key)" type="primary">配置</a-button>
							</span>
						</a-table>
					</a-form-item>
				</a-form>
			</a-tab-pane>
			<a-tab-pane key="3" tab="移动端">移动端</a-tab-pane>
			<a-tab-pane key="4" tab="客户端">客户端</a-tab-pane>
			<a-tab-pane key="5" tab="看板端">看板端</a-tab-pane>
		</a-tabs>
		<div class="title">数据权限</div>
		<a-table :columns="dataPermissionColumns" :data-source="detail.dataPermission" bordered :pagination="false"
			size="small">
			<span slot="button" slot-scope="text,record">
				<a-button @click="showPop(record.key)" type="primary">字段配置</a-button>
			</span>
		</a-table>
		<template v-if="detail.removeReason">
			<div class="title">下架原因</div>
			<a-form :model="detail.removeReason" layout="inline">
				<a-form-item label="填写时间">
					<div>{{detail.removeReason.applyTime}}</div>
				</a-form-item>
				<a-form-item label="原因">
					<div>{{detail.removeReason.reason}}</div>
				</a-form-item>
			</a-form>
		</template>
		<div class="title">历史审批记录</div>
		<a-table :columns="historyColumns" :data-source="detail.history" :pagination="false" size="small">
			<span slot="action" slot-scope="text">{{text.description}}</span>
		</a-table>
		<template v-if="detail.operation.remove||detail.operation.reject||detail.operation.pass">
			<a-textarea class="formInputWidth" :maxLength="100" v-model="result" placeholder="填写审批结果" />
		</template>
		<div class="flex-row">
			<a-button @click="onRemove" type="danger" v-if="detail.operation.remove">下架</a-button>
			<a-button @click="onReject" type="danger" v-if="detail.operation.reject">驳回</a-button>
			<a-button @click="onPass" type="primary" v-if="detail.operation.pass">通过</a-button>
			<a-button @click="backOrderList" type="primary">返回</a-button>
		</div>
		<a-modal title="按钮配置" width="635px" :visible="buttonSetting" @ok="buttonSetting=false"
			:cancel-button-props="{ style: {display:'none'}}">
			<a-table :columns="modalColumns" :pagination="false"
				:data-source="detail.app.web.menus[operatedLine].buttons" bordered size="small"></a-table>
		</a-modal>
		<a-modal title="字段配置" width="788px" :visible="popup" @ok="popup=false"
			:cancel-button-props="{ style: {display:'none'}}">
			<a-table v-if="detail.dataPermission.length>0" :columns="dataPermissionPop" :pagination="false"
				:data-source="detail.dataPermission[operatedLine].fields" bordered size="small">
				<span slot="type" slot-scope="text">{{text}}</span>
			</a-table>
		</a-modal>
	</div>
</template>
<script>
	import { GetSecretObsClient, getAkAndSk } from "../../utils/obs.js"
	import { Modal } from 'ant-design-vue'
	import pagination from "@/mixins/Pagination"
	import { appCheckApi } from "@/api/appCheckApi.js"
	export default {
		mixins: [pagination],
		data() {
			return {
				businessTypeList: {
					'1': '生产管控',
					'2': '安全环保',
					'3': '供应链管理',
					'4': '能源管理',
					'5': '资产管理',
					'0': '其他'
				}, //解决方案分类
				buju: { labelCol: { span: 3 }, wrapperCol: { span: 13 } },
				loading: false,
				status: null,
				sourceData: [],
				result: '',
				priceColumns: [{
					title: '用户数量',
					dataIndex: 'userScale',
					align: 'center',
					width: 150,
					scopedSlots: { customRender: "userScale" }
				}, {
					title: '一个月',
					dataIndex: 'month',
					align: 'center'
				}, {
					title: '一季度',
					dataIndex: 'quarter',
					align: 'center'
				}, {
					title: '半年',
					dataIndex: 'halfYear',
					align: 'center'
				}, {
					title: '一年',
					dataIndex: 'year',
					align: 'center'
				}, {
					title: '三年',
					dataIndex: 'threeYears',
					align: 'center'
				}, {
					title: '五年',
					dataIndex: 'fiveYears',
					align: 'center'
				}],
				price: [],
				showDatabase: true,
				webForm: {},
				jarForm: {},
				dataPermissionColumns: [{
					title: '序号',
					dataIndex: 'sort',
					align: 'center',
				}, {
					title: '表中文名称',
					dataIndex: 'cnName',
					align: 'center'
				}, {
					title: '表英文名称',
					dataIndex: 'enName',
					align: 'center'
				}, {
					title: '字段配置',
					dataIndex: 'fileds',
					align: 'center',
					scopedSlots: { customRender: "button" }
				}],
				resourceOptions: [{
						label: 'MySQL',
						value: 1
					}, {
						label: 'MongoDB',
						value: 2
					}, {
						label: 'Redis',
						value: 3
					}, {
						label: 'RocketMQ',
						value: 4
					},
					// {
					// 	label: 'Nebula',
					// 	value: 5
					// }, {
					// 	label: 'influxDB',
					// 	value: 6
					// },
				],
				menus: [{
					title: '菜单顺序',
					dataIndex: 'sort',
					key: 'sort'
				}, {
					title: '菜单英文名称',
					dataIndex: 'enName',
					key: 'enName'
				}, {
					title: '菜单中文名称',
					dataIndex: 'cnName',
					key: 'cnName'
				}, {
					title: '菜单路径',
					dataIndex: 'path',
					key: 'path'
				}, {
					title: '按钮配置',
					dataIndex: 'setting',
					key: 'setting',
					scopedSlots: { customRender: "button" }
				}],
				detail: {
					basic: {
						businessType: 0, // 业务类型
						docFile: {
							name: '',
							url: ''
						}
					},
					app: {
						server: {
							packageInfo: {},
							image: '',
							resources: {
								needed: []
							}
						},
						web: {
							packageInfo: {},
							menus: [{
								key: 0,
								sort: 1,
								buttons: [{
									cnName: "",
									enName: "",
									key: 0,
									sort: 1
								}],
								cnName: "",
								enName: "",
								path: "",
							}],
						}
					},
					dataPermission: [{
						fields: [],
						key: 0
					}],
					history: [],
					price: [],
					removeReason: {},
					operation: {
						remove: false,
						reject: false,
						pass: false,
					}
				},
				buttonSetting: false,
				popup: false,
				modalColumns: [{
					title: '序号',
					dataIndex: 'sort',
					key: 'sort'
				}, {
					title: '按钮中文名称',
					dataIndex: 'cnName',
					key: 'cnName'
				}, {
					title: '按钮英文名称',
					dataIndex: 'enName',
					key: 'enName'
				}],
				operatedLine: 0,
				historyColumns: [{
					title: "审批账号",
					dataIndex: "accountName",
					key: "accountName"
				}, {
					title: "审批时间",
					dataIndex: "auditTime",
					key: "auditTime"
				}, {
					title: "审批类型",
					dataIndex: "action",
					key: "action",
					scopedSlots: { customRender: "action" }
				}, {
					title: "审批结果",
					key: "result",
					dataIndex: "result"
				}],
				dataPermissionPop: [{
					title: '序号',
					dataIndex: 'sort',
					key: 'sort'
				}, {
					title: '字段类型',
					dataIndex: 'type',
					key: 'type',
					scopedSlots: { customRender: "type" }
				}, {
					title: '字段中文名称',
					dataIndex: 'cnName',
					key: 'cnName'
				}, {
					title: '字段英文名称',
					dataIndex: 'enName',
					key: 'enName'
				}]
			};
		},
		mounted() {
			this.getSourceData()
		},
		methods: {
			computedType(type) {
				return type == 1 ? "111" : '字符串'
			},
			//华为云下载的三个方法
			async setSKandAK(fileName) {
				let ak = await getAkAndSk("/cloud-platform-backend-facade/front-api/v1/common/object/akAndSk");
				if (ak) {
					//缓存至本地临时仓库
					window.sessionStorage.setItem("securitytoken", ak.securitytoken);
					window.sessionStorage.setItem("secret", ak.secret);
					window.sessionStorage.setItem("access", ak.access);
					window.sessionStorage.setItem("expires_at", ak.expires_at);
					//初始化传进来的文件列表
					this.handleDown(fileName);
				} else {
					this.$message.error("临时密钥获取失败");
				}
			},
			async handleDown(fileName) {
				let sk = {
					securitytoken: window.sessionStorage.getItem("securitytoken"),
					secret: window.sessionStorage.getItem("secret"),
					access: window.sessionStorage.getItem("access"),
				};
				let tempfile = await GetSecretObsClient(fileName, "datarun-cloud-sec", sk);
				let link = document.createElement("a");
				fetch(tempfile.fileAddress).then((res) => res.blob()).then((blob) => {
					// 将链接地址字符内容转变成blob地址
					link.href = URL.createObjectURL(blob);
					link.download = tempfile.fileName;
					document.body.appendChild(link);
					link.click();
				});
			},
			async download(item) {
				// let fileName = item.substring(item.lastIndexOf("com") + 4); //文件名
				let fileName = item
				const expires = sessionStorage.getItem("expires_at");
				const securityToken = sessionStorage.getItem("securitytoken");
				//是否有临时token等信息
				if (expires && securityToken) {
					if (new Date(expires).getTime() < Date.now()) {
						//过期，请求临时token
						await this.setSKandAK(fileName);
					} else {
						//未过期，初始化
						this.handleDown(fileName);
					}
				} else {
					//为满足，请求临时令牌
					await this.setSKandAK(fileName);
				}
			},
			//查看字段设置
			showPop(rowKey) {
				this.operatedLine = rowKey
				this.popup = true
			},
			//查看按钮设置
			showButton(rowKey) {
				this.operatedLine = rowKey
				this.buttonSetting = true
			},
			//查询数据接口
			getSourceData() {
				this.loading = true
				appCheckApi.GetAppDetail({
					id: this.$route.query.id
				}).then((res) => {
					if (res.success) {
						this.loading = false
						Object.keys(this.detail).forEach(key => {
							if (res.data[key]) {
								if (key === "history") {
									if (this.detail.history.length === 0) {
										this.detail.history.push(res.data.history)
									}
									this.setKey(this.detail.history)
								} else {
									this.detail[key] = res.data[key]
								}
							}
						})
						this.setKey(this.detail.price)
						this.detail.app.web.menus.forEach((item, index) => {
							item.sort = index + 1
							item.key = index
							if (item.buttons) {
								item.buttons.forEach((innerItem, innerIndex) => {
									innerItem.key = innerIndex
									innerItem.sort = innerIndex + 1
								})
							}
						})
						this.detail.dataPermission.forEach((item, index) => {
							item.sort = index + 1
							item.key = index
							item.fields.forEach((innerItem, innerIndex) => {
								innerItem.key = innerIndex
								innerItem.sort = innerIndex + 1
							})
						})
					} else {
						this.loading = false
						this.$message.error(res.errorMsg)
					}
				})
			},
			setKey(arr) {
				if (arr) {
					arr.forEach((item, index) => {
						item.sort = index + 1
						item.key = index
					})
				}
			},
			//下架
			onRemove() {
				appCheckApi.Verify({
					id: this.$route.query.id,
				}).then(res => {
					if (res.success) {
						if (res.data.safe) {
							Modal.confirm({
								title: '是否确认下架此应用？',
								content: `应用名称：${this.detail.basic.name}`,
								onOk: () => {
									this.apiRemove()
								}
							})
						} else {
							const solutionName = res.data.solutions.map(item => `【${item.name}】`)
							Modal.confirm({
								title: '此应用已包含在以下解决方案中，如下架应用则解决方案中的该应用将自动移除',
								content: this.$createElement('div', {
									style: 'color:#999999',
									// class: 'flex-column'
								}, [this.$createElement('span', `${solutionName}`)]),
								onOk: () => {
									this.apiRemove()
								}
							})
						}
					} else {
						this.$message.error(res.errorMsg)
					}
				})
			},
			apiRemove() {
				appCheckApi.Remove({
					id: this.$route.query.id,
					result: this.result
				}).then(res => {
					if (res.success) {
						this.$message.success("下架成功")
						this.result = ""
						this.getSourceData()
					} else {
						this.$message.error(res.errorMsg)
					}
				})
			},
			//驳回
			onReject() {
				Modal.confirm({
					title: '是否确认驳回此应用',
					content: `应用名称：${this.detail.basic.name}`,
					onOk: () => {
						appCheckApi.Reject({
							id: this.$route.query.id,
							result: this.result
						}).then(res => {
							if (res.success) {
								this.$message.success("当前应用已驳回")
								this.result = ""
								this.getSourceData()
							} else {
								this.$message.error(res.errorMsg)
							}
						})
					}
				})
			},
			//通过
			onPass() {
				Modal.confirm({
					title: '是否确认通过此应用',
					content: `应用名称：${this.detail.basic.name}`,
					onOk: () => {
						appCheckApi.Pass({
							id: this.$route.query.id,
							result: this.result
						}).then(res => {
							if (res.success) {
								this.$message.success("应用审核通过！")
								this.result = ""
								this.getSourceData()
							} else {
								this.$message.error(res.errorMsg)
							}
						})
					}
				})
			},
			backOrderList() {
				this.$router.back()
			}
		},
	};
</script>
<style lang="scss" scoped>
	.container {
		padding-left: 3rem;

		.icon {
			width: 2.4rem;
			height: 2.4rem;
		}

		.screenshots {
			width: 10rem;
		}

		.flex-row {
			.ant-btn {
				width: 8rem;
				margin-right: 3rem;
			}
		}

		.title {
			// smargin-left: 5rem;
			line-height: 1.2rem;
			height: 1.2rem;
			font-size: 1.2rem;
			margin-bottom: 2rem;
			margin-top: 2rem;
		}

		.title:before {
			content: '';
			display: inline-block;
			width: 0.3rem;
			height: 100%;
			background-color: #1a8af3;
			margin-right: .5rem;
			margin-bottom: -.15rem;
		}

		/deep/ label {
			font-size: 1rem;
			font-weight: 600 !important;
		}

		.value {
			width: 20rem;
		}

		.formInputWidth {
			width: 40rem;
			height: 10rem;
			margin-top: 3rem;
			margin-bottom: 3rem;
		}
	}

	// 新增 - sheng
	.back-btn-s {
		margin: 1rem auto;
		transform: translateX(35vw);
	}
</style>
